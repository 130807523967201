import React, { ReactNode, Suspense, useEffect, useState } from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import TagManager from "react-gtm-module";
import * as Sentry from "@sentry/react";

import "animate.css/animate.min.css";
import "./styles/app.scss";

import Header from "./components/Layout/Header";
import Sidebar from "./components/Layout/Sidebar";
import Footer from "./components/Layout/Footer";

import {
  SignInCallback,
  SignOutCallback,
  SignInSilentCallback,
} from "./components/Auth/Callback";
import { AuthProvider } from "./components/Auth/AuthProvider";
import { AuthenticatedRoute } from "./components/Auth/AuthenticatedRoute";
import { LicenseProvider } from "./components/Auth/LicenseProvider";
import { LoadingWrapper } from "./components/LoadingWrapper";
import { AuthorizationProvider } from "./components/Auth/AuthorizationProvider";
import RootStore from "./store/RootStoreContext";
import lazyWithRetry from "./LazyWithRetry";
import CreateFeedback from "./components/Feedback/CreateFeedback";
import { ThemeProvider } from "./store/ThemeContext";
import ModalMessage from "./components/Common/ModalMessage";
import ErrorInterface from "./components/Common/ErrorInterface";
import AdminGuard from "./components/Admin/AdminGuard";

const Requirements = lazyWithRetry(
  () => import("./components/Products/Requirements")
);
const LegalActsPage = lazyWithRetry(
  () => import("./components/LegalActs/LegalActsPage")
);
const GlobalStandardFinder = lazyWithRetry(
  () => import("./components/Tools/GlobalStandardFinder/GlobalStandardFinder")
);
const GlobalLegislationFinder = lazyWithRetry(
  () =>
    import("./components/Tools/GlobalLegislationFinder/GlobalLegislationFinder")
);
const CreateMarking = lazyWithRetry(
  () => import("./components/Admin/Markings/CreateMarking")
);
const MarkingsEdit = lazyWithRetry(
  () => import("./components/Admin/Markings/MarkingsEdit")
);
const ActivityLog = lazyWithRetry(() => import("./components/ActivityLog"));
const NotificationsList = lazyWithRetry(
  () => import("./components/Admin/Notifications/NotificationsList")
);
const Statistics = lazyWithRetry(() => import("./components/Admin/Statistics"));
const NotificationDetails = lazyWithRetry(
  () => import("./components/Admin/Notifications/NotificationDetails")
);
const NotificationEdit = lazyWithRetry(
  () => import("./components/Admin/Notifications/NotificationEdit")
);
const NotificationCreate = lazyWithRetry(
  () => import("./components/Admin/Notifications/NotificationCreate")
);

const LegalActsList = lazyWithRetry(
  () => import("./components/Admin/LegalAct/LegalActsList")
);
const NewsAdminList = lazyWithRetry(
  () => import("./components/Admin/News/NewsList")
);
const NewsAdminCreate = lazyWithRetry(
  () => import("./components/Admin/News/NewsCreate")
);

const CreateLegalAct = lazyWithRetry(
  () => import("./components/Admin/LegalAct/CreateLegalAct")
);
const EditLegalAct = lazyWithRetry(
  () => import("./components/Admin/LegalAct/LegalActsEdit")
);

const MarkingsList = lazyWithRetry(
  () => import("./components/Admin/Markings/MarkingsList")
);

const CommingSoon = lazyWithRetry(
  () => import("./components/Common/CommingSoon")
);
const Tools = lazyWithRetry(() => import("./components/Tools/Tools"));
const Standards = lazyWithRetry(
  () => import("./components/Tools/Standards/Standards")
);
const Products = lazyWithRetry(() => import("./components/Products/Products"));
const LegislationFinder = lazyWithRetry(
  () => import("./components/Tools/LegislationFinder/LegislationFinder")
);
const DocumentationGenerator = lazyWithRetry(
  () =>
    import("./components/Tools/DocumentationGenerator/DocumentationGenerator")
);
const DocumentationRequirement = lazyWithRetry(
  () =>
    import(
      "./components/Tools/DocumentationRequirement/DocumentationRequirement"
    )
);
const NotifiedBodies = lazyWithRetry(
  () => import("./components/Tools/NotifiedBodies/NotifiedBodies")
);
const StandardDetails = lazyWithRetry(
  () => import("./components/Tools/Standards/StandardDetails")
);
const DocumentationManager = lazyWithRetry(
  () => import("./components/Tools/DocumentationManager/DocumentationManager")
);
const Dashboard = lazyWithRetry(
  () => import("./components/Dashboard/Dashboard")
);
const SharedFile = lazyWithRetry(
  () => import("./components/SharedFile/SharedFile")
);
const HarmonisedStandards = lazyWithRetry(
  () => import("./components/Tools/HarmonisedStandards/HarmonisedStandards")
);
const Store = lazyWithRetry(() => import("./components/Store/StoreOptions"));
const NeedsGuide = lazyWithRetry(
  () => import("./components/NeedsGuide/NeedsGuide")
);
const ViewProduct = lazyWithRetry(
  () => import("./components/Products/ViewProduct")
);
const NotFound = lazyWithRetry(() => import("./components/Layout/NotFound"));
const TaskList = lazyWithRetry(
  () => import("./components/Roadmaps/TaskListPage")
);
const Notifications = lazyWithRetry(
  () => import("./components/Notifications/Notifications")
);
const FreeTrial = lazyWithRetry(() => import("./components/Store/FreeTrial"));
const CompanyRegister = lazyWithRetry(
  () => import("./components/Store/CompanyRegister")
);
const Onboarding = lazyWithRetry(() => import("./components/Home/Onboarding"));
const UlStandards = lazyWithRetry(
  () => import("./components/Tools/UlStandards/UlStandards")
);
const GlobalMarkings = lazyWithRetry(
  () => import("./components/Tools/GlobalMarkings/GlobalMarkings")
);
const Monitoring = lazyWithRetry(
  () => import("./components/Admin/Monitoring/Monitoring")
);
const NewsList = lazyWithRetry(() => import("./components/News/NewsList"));
const AcceptInvitation = lazyWithRetry(
  () => import("./components/Admin/AcceptInvitation")
);
const AdminView = lazyWithRetry(() => import("./components/Admin/AdminView"));
const Tasks = lazyWithRetry(() => import("./components/Tasks/Overview"));
const Details = lazyWithRetry(() => import("./components/Products/Details"));

//Production
const tagManagerArgs = {
  gtmId: "GTM-KLWGX3D",
  auth: "841ZdMezl5GVmzRC_vyQ4w",
  preview: "env-2",
};

//Development
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  tagManagerArgs.auth = "MJjSwhrzo3aAy1mnPm_Rxg";
  tagManagerArgs.preview = "env-5";
} else if (process.env.REACT_APP_ENVIRONMENT === "staging") {
  tagManagerArgs.auth = "eR-5mRhpQ-ndtxlAHcqyGQ";
  tagManagerArgs.preview = "env-7";
} else {
  //Production
  Sentry.init({
    dsn: "https://67cb3320f1eb46798a9a83b1315d2be5@o275291.ingest.sentry.io/5596639",
    autoSessionTracking: true,
  });
  console.error = () => {};
  console.warn = () => {};
}

TagManager.initialize(tagManagerArgs);

const myFallback = <ErrorInterface />;

const App = function () {
  const [showModal, setShowModal] = useState(false);
  const [showModalMessage, setShowModalMessage] = useState(false);
  const [loadedSections, setLoadedSections] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1024) {
        setShowModalMessage(true);
      } else {
        setShowModalMessage(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClose = () => setShowModalMessage(false);

  const closeModal = () => {
    setShowModal(false);
  };
  function setLoaded() {
    setLoadedSections((a) => a + 1);
  }
  return (
    <Sentry.ErrorBoundary fallback={myFallback}>
      <BrowserRouter>
        <RootStore>
          <ThemeProvider>
            <AuthProvider>
              <LicenseProvider>
                <AuthorizationProvider>
                  <div className="layout-fixed">
                    <div className="wrapper">
                      <Header />
                      <Sidebar />
                      <Suspense
                        fallback={
                          <LoadingWrapper
                            loaders={[
                              { isLoading: true, text: "Loading page..." },
                            ]}
                          >
                            Loading...
                          </LoadingWrapper>
                        }
                      >
                        <Routes>
                          <Route path="/" element={<Dashboard />} />

                          {/*  <<<<<<< CRUD >>>>>>>>> */}
                          <Route element={<AdminGuard />}>
                            <Route
                              path="/legal-act/create"
                              element={<CreateLegalAct />}
                            />
                            <Route
                              path="/legal-act/list"
                              element={<LegalActsList />}
                            />
                            <Route
                              path="/legal-act/edit"
                              element={<EditLegalAct />}
                            />
                            <Route
                              path="/markings/list"
                              element={<MarkingsList />}
                            />
                            <Route
                              path="/markings/create"
                              element={<CreateMarking />}
                            />
                            <Route
                              path="/markings/edit"
                              element={<MarkingsEdit />}
                            />
                            <Route
                              path="/news/list"
                              element={<NewsAdminList />}
                            />
                            <Route
                              path="/news/create"
                              element={<NewsAdminCreate />}
                            />
                            <Route
                              path="/news/edit"
                              element={<NewsAdminCreate />}
                            />
                            <Route
                              path="/requirements"
                              element={<Requirements />}
                            />
                          </Route>

                          {/* <<<<<<<<<<<< >>>>>>>>>>>>>> */}

                          <Route
                            path="/admin/statistics"
                            element={<Statistics />}
                          />
                          <Route
                            path="/admin/notifications"
                            element={<NotificationsList />}
                          />
                          <Route
                            path="/admin/notifications/:id"
                            element={<NotificationDetails />}
                          />
                          <Route
                            path="/admin/notifications/:id/edit"
                            element={<NotificationEdit />}
                          />
                          <Route
                            path="/admin/notifications/create"
                            element={<NotificationCreate />}
                          />
                          <Route
                            path="/sharedfile/:id/:hash"
                            element={<SharedFile />}
                          />
                          <Route
                            path="/onboarding"
                            element={
                              <AuthenticatedRoute>
                                <Onboarding />
                              </AuthenticatedRoute>
                            }
                          />
                          <Route
                            path="/store/free-trial"
                            element={<FreeTrial />}
                          />
                          <Route
                            path="/store/company-register"
                            element={<CompanyRegister />}
                          />
                          <Route path="/store" element={<Store />} />
                          <Route path="/store/:category?" element={<Store />} />
                          <Route path="/needs-guide" element={<NeedsGuide />} />
                          <Route path="/tools" element={<Tools />} />
                          <Route
                            path="/account/signin-oidc"
                            element={<SignInCallback />}
                          />
                          <Route
                            path="/account/signout-oidc"
                            element={<SignOutCallback />}
                          />
                          <Route
                            path="/account/silent-renew"
                            element={<SignInSilentCallback />}
                          />
                          <Route
                            path="/tools/legislation-finder"
                            element={<LegislationFinder />}
                          />
                          <Route
                            path="/tools/harmonised-standards"
                            element={<HarmonisedStandards />}
                          />
                          <Route
                            path="/tools/standards"
                            element={<Standards />}
                          />
                          <Route
                            path="/tools/standards/:id"
                            element={<StandardDetails />}
                          />
                          <Route
                            path="/tools/ul-standards"
                            element={<UlStandards />}
                          />
                          <Route
                            path="/tools/documentation-requirements"
                            element={<DocumentationRequirement />}
                          />
                          <Route
                            path="/tools/notified-bodies"
                            element={<NotifiedBodies />}
                          />
                          <Route
                            path="/tools/documentation-manager"
                            element={<DocumentationManager />}
                          />
                          <Route
                            path="/tools/documentation-generator"
                            element={<DocumentationGenerator />}
                          />
                          <Route
                            path="/tools/standard-finder"
                            element={
                              <GlobalStandardFinder onLoaded={setLoaded} />
                            }
                          />
                          <Route
                            path="/tools/global-legislation-finder"
                            element={
                              <GlobalLegislationFinder onLoaded={setLoaded} />
                            }
                          />
                          <Route
                            path="/tools/global-markings"
                            element={<GlobalMarkings />}
                          />
                          <Route
                            path="/notifications"
                            element={<Notifications />}
                          />
                          <Route path="/products" element={<Products />} />
                          <Route
                            path="/products/:id"
                            element={<ViewProduct />}
                          />
                          <Route
                            path="/products/:id/:overview"
                            element={<ViewProduct />}
                          />
                          <Route
                            path="/products/:id/:details"
                            element={<Details />}
                          />
                          <Route path="/tasks" element={<Tasks />} />
                          <Route
                            path="/admin/monitoring"
                            element={<Monitoring />}
                          />
                          <Route path="/news" element={<NewsList />} />
                          <Route
                            path="/user/invitation/:token"
                            element={<AcceptInvitation />}
                          />
                          <Route
                            path="/tools/documentation-manager/:tab?/:productId?"
                            element={<DocumentationManager />}
                          />
                          <Route path="/admin/*" element={<AdminView />} />
                          {/* new pages */}
                          <Route
                            path="/supplier-portal"
                            element={
                              <CommingSoon
                                header="Discover Our Upcoming Supplier Portal"
                                description="Supplier Portal, currently in development, designed to streamline the way companies gather essential data from their suppliers. This new feature will empower businesses to efficiently report and manage compliance across their supply chain."
                              />
                            }
                          />
                          <Route
                            path="/risk-monitor"
                            element={
                              <CommingSoon
                                header="Risk Monitor Under Development"
                                description="We are in the advanced stages of developing a new feature for our platform - Risk Monitor, designed in alignment with the ISO 31000 standard. This tool aims to transform how businesses assess and handle risks in compliance. "
                              />
                            }
                          />
                          <Route
                            path="/integration"
                            element={
                              <CommingSoon
                                header="Extending our Integrations"
                                description="Our upcoming integration functionality, designed to seamlessly connect your inventory management to your business systems. This new feature will streamline operations, reduce errors, and provide real-time insights across your inventory."
                              />
                            }
                          />
                          <Route
                            path="/declaration-manager"
                            element={
                              <CommingSoon
                                header="Introducing Our All-in-One Declaration Management Tool"
                                description="We're currently developing a comprehensive tool designed to simplify the collection, analysis, and management of mandatory declarations, including RoHS, REACH, SCIP and many more. This all-in-one solution replaces the need for multiple tools, ensuring a streamlined, efficient approach to staying compliant with regulations."
                              />
                            }
                          />
                          <Route
                            path="/training-resources"
                            element={
                              <CommingSoon
                                header="Empower Your Team with Our Upcoming Compliance Training Module"
                                description="Designed to transform your compliance training, this module focuses on critical areas such as onboarding personnel and educating teams. This module will host educational materials, videos, protocols, codes of conduct, all customizable and enterable by your organization."
                              />
                            }
                          />
                          <Route
                            path="/activity-log"
                            element={<ActivityLog />}
                          />
                          <Route
                            path="/notifications"
                            element={<Notifications />}
                          />
                          <Route path="/roadmaps" element={<TaskList />} />
                          <Route
                            path="/legal-acts"
                            element={<LegalActsPage />}
                          />
                          <Route element={<NotFound />} />
                        </Routes>
                      </Suspense>
                      <Footer />
                      <button
                        className="fixed-button"
                        onClick={() => {
                          setShowModal(true);
                        }}
                      >
                        Feedback
                      </button>
                      <CreateFeedback
                        showModal={showModal}
                        closeModal={closeModal}
                      />
                      <ModalMessage
                        showModal={showModalMessage}
                        handleClose={handleClose}
                      />
                    </div>
                  </div>
                </AuthorizationProvider>
              </LicenseProvider>
            </AuthProvider>
          </ThemeProvider>
        </RootStore>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  );
};

export default App;
